import React from 'react';
import { useTranslations } from 'next-intl';

import { Link } from '@zealy/design-system';

import { PRIVACY_URL, TERMS_AND_CONDITIONS_URL } from '#constants';

export const AcceptTerms = () => {
  const tFooter = useTranslations('signup');

  return (
    <span className="text-secondary body-md text-center">
      {tFooter.rich('terms-and-conditions', {
        terms: chunks => (
          <Link
            underlined
            target="_blank"
            rel="noopener noreferrer"
            href={TERMS_AND_CONDITIONS_URL}
          >
            {chunks}
          </Link>
        ),
        privacy: chunks => (
          <Link underlined target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
            {chunks}
          </Link>
        ),
      })}
    </span>
  );
};
